import { useQuery } from 'react-query';
import { Product } from '../../types/shopify/products/Product.type';

export type SuggestedProductsQuery = {
  suggestedProduct?: Product;
};

const fetchSuggestedProducts = async (params?: string) => {
  const res = await window.fetch(`/api/suggestedProducts/?sw=${params}`);
  return res.json();
};

const useSuggestedProductsQuery = (params?: string) => {
  const formattedParams = params ? params.trim() : '';
  return useQuery<{
    suggestedProducts: Product[];
  }>(
    ['suggestedProducts', formattedParams],
    () => fetchSuggestedProducts(formattedParams),
    {
      refetchOnWindowFocus: false,
      enabled: !!formattedParams,
    },
  );
};

export default useSuggestedProductsQuery;
