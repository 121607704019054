import React, { useCallback, useEffect, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import searchIcon from '../../../public/Icon/shop/search.png';
import useSuggestedProductsQuery from '../../../hooks/top/useSuggestedProducts';
import UplText from '../../atoms/UplText/UplText';
import useDebounce from '../../../hooks/top/useDebounce';

const UplProductSearchBox: React.FC<{ dataCy: string }> = ({ dataCy }) => {
  const [title, setTitle] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();

  useEffect(() => {
    setTitle('');
  }, [router.asPath]);

  const debouncedTitle = useDebounce(title, 300);
  const { data, isFetching } = useSuggestedProductsQuery(debouncedTitle);

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (router.pathname === '/') {
        router.push({
          pathname: '/',
          query: { ...router.query, sw: title, pi: 0 },
        });
      } else {
        router.push({
          pathname: '/',
          query: { sw: title },
        });
      }
    },
    [router, title],
  );

  return (
    <>
      <form
        className='relative z-10 flex flex-col items-center'
        onSubmit={handleSubmit}
      >
        <div className='flex w-full items-center justify-center md:justify-center'>
          <div className='relative sm:w-full md:w-2/4 lg:w-full'>
            <input
              type='text'
              className='rounded-l rounded-r-none border border-gray-200 p-4 outline-none sm:h-10 sm:w-full md:h-10 md:w-full lg:h-16 lg:w-full'
              placeholder='キーワードで検索'
              value={title || ''}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              onFocus={() => setIsOpen(true)}
              data-cy={`key-search-text-box-${dataCy}`}
            />
            {isOpen && (
              <ul
                className='absolute max-h-64 overflow-y-auto rounded rounded-t-none bg-white shadow-lg sm:w-full md:mr-10 md:w-full lg:mr-11 lg:w-full'
                data-cy={`vendor-search-list-${dataCy}`}
              >
                {!isFetching && data
                  ? data.suggestedProducts?.map((p) => (
                      <li
                        className='mx-2 list-none border-b-2 border-white-gray p-2 last:border-none hover:cursor-pointer hover:bg-white-gray'
                        key={p.title}
                        onClick={() =>
                          router.push({
                            pathname: '/',
                            query: { ...router.query, sw: p.title, pi: 0 },
                          })
                        }
                        aria-hidden
                      >
                        <UplText variant='subtext'>{p.title}</UplText>
                      </li>
                    ))
                  : null}
              </ul>
            )}
          </div>
          <button
            type='submit'
            className='cursor-pointer rounded-r bg-dark-gray p-2 text-center hover:bg-dark-gray-300 sm:size-10 md:size-10 lg:h-16 lg:w-14 lg:py-5'
            data-cy={`key-search-button-${dataCy}`}
          >
            <Image
              src={searchIcon}
              width={20}
              height={20}
              alt='検索'
              data-cy='key-search-icon-image'
            />
          </button>
        </div>
      </form>
      {isOpen && (
        <div
          className='fixed left-0 top-0 z-0 h-screen w-screen'
          aria-hidden
          onClick={() => setIsOpen(false)}
          data-cy='hidden-screen'
        />
      )}
    </>
  );
};

export default UplProductSearchBox;
