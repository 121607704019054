import provinceChange from '../../modules/util/customer/address';
import { CustomerAddress } from '../../types/shopify/customers/CustomerAddress.type';
import { PhoneNumberModel } from './PhoneNumberModel';

export default class AddressModel {
  private data?: CustomerAddress;

  private phoneNumber?: PhoneNumberModel;

  constructor(data?: CustomerAddress) {
    this.data = data;
    this.phoneNumber = this.data?.phone
      ? new PhoneNumberModel(this.data?.phone)
      : undefined;
  }

  /**
   * 住所
   */
  get address() {
    if (!this.data) return undefined;
    return `${provinceChange(this.data?.province)} ${this.data?.city || ''} ${
      this.data?.address1 || ''
    } ${this.data?.address2 || ''}`;
  }

  /**
   * 郵便番号
   */
  get zip() {
    if (!this.data) return undefined;
    const zip = this.data?.zip;
    return `〒${zip?.slice(0, 3)}${zip?.slice(3)}`;
  }

  /**
   * ハイフン無しの郵便番号
   */
  get zipWithoutHiphen() {
    if (!this.data) return undefined;
    return this.data.zip?.replace('-', '');
  }

  /**
   * 氏名
   */
  get firstName() {
    return this.data?.firstName;
  }

  /**
   * 会社名
   */
  get company() {
    return this.data?.lastName;
  }

  /**
   * id
   */
  get id() {
    return this.data?.id?.split('?')[0];
  }

  /**
   * 表示用電話番号
   */
  get displayPhone() {
    return this.phoneNumber?.displayPhoneNumber || '';
  }

  get originalData() {
    return this.data;
  }

  /**
   * 配送住所
   */
  get shippingAddress() {
    return {
      firstName: this.data?.firstName?.trim(),
      lastName: this.data?.recipientCompany
        ? this.data?.recipientCompany.trim()
        : this.data?.lastName?.trim(),
      country: this.data?.country,
      zip: this.data?.zip?.trim(),
      province: this.data?.province?.trim(),
      city: this.data?.city?.trim(),
      address1: this.data?.address1?.trim(),
      address2: this.data?.address2?.trim(),
      phone: this.phoneNumber?.E164PhoneNumber || '',
    };
  }

  equals(id?: string) {
    return this.id === id;
  }
}
